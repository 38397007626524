const ProductCard = (prop) => {
  const image = prop.card.product_category_img;
  return (
    <div className="relative max-h-[150px] max-w-[75px] flex flex-col  align-middle justify-center items-center">
      {prop.card.product_off ? (
        <div className=" absolutemb-1 bg-[#e30015] h-[20px] p-3  rounded-md w-full  max-w-[250px] flex items-center justify-center align-middle ">
          <h3 className="text-white text-sm text-center">
            {prop.card.product_off_amount}
          </h3>
        </div>
      ) : (
        ""
      )}
      <img src={image} alt="category" />
      <h3 className="text-[14px] text-center mt-2 text-black">
        {prop.card.product_category_name}
      </h3>
    </div>
  );
};

export default ProductCard;
